import React from 'react'
// import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
// import { StaticQuery, graphql } from 'gatsby'
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import {
//   amber,
//   blue
// } from '@material-ui/core/colors';
import {
  CssBaseline,
  Container,
  Typography,
  Link
} from '@material-ui/core';

import './layout.css'
import Header from './header';
// import LazyHydrate from "react-lazy-hydration";


const Layout = ({ children }) => {

      return (
          <>
          {/* <LazyHydrate ssrOnly> */}

            <Helmet
              title='Exciting Theory'
              meta={[
                { name: 'description', content: 'Videos and other cool things' },
                { name: 'keywords', content: 'OTT, VOD, Learning Tools' },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <CssBaseline />
          {/* </LazyHydrate> */}
          
          {/* <LazyHydrate whenVisible>      */}
    <Header siteTitle='Exciting Theory' />
    {/* </LazyHydrate> */}
    
                { children }
  {/* <LazyHydrate ssrOnly> */}
  <footer>
    <Container maxWidth="xs" justify="center" align="center">

      <Typography variant="body2" color="textPrimary">
        {'Copyright © '}
        <Link color="inherit" href="/">
          Exciting Theory
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Container>
  </footer>
  {/* </LazyHydrate> */}
            </>
  
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout