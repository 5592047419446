import React from 'react'
import { Auth } from 'aws-amplify'
import AccountCircle from '@material-ui/icons/AccountCircle';
import { SignOut } from 'aws-amplify-react'
import { Hub } from 'aws-amplify';

import {
  AppBar,
  Drawer,
  Toolbar,
  IconButton,
  MenuItem,
  Button,
  List,
  Menu,
  Typography
} from '@material-ui/core';

import { Link as GLink } from 'gatsby';

import MenuIcon from '@material-ui/icons/Menu';

class Header extends React.Component {

  constructor(props) {
    super(props);
    const anchorEl = null
    const open = false
    const drawerOpen = false
    const prevOpen = false
    const prevMenuOpen = false
    const menuOpen = false
    const loggedInStatus = false

    this.state = {
      open,
      anchorEl,
      drawerOpen,
      menuOpen,
      prevMenuOpen,
      prevOpen,
      loggedInStatus
    };

    this.handleClick = this.handleClick.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  async componentDidMount() {
    let that = this
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          // console.log('user signed in');
          that.isLoggedIn(true);
          break;
        case 'signOut':
          // console.log('user signed out');
          that.isLoggedIn(false);
          break;
      }
    });

    await Auth.currentAuthenticatedUser()
      .then(() => { this.isLoggedIn(true); })
      .catch(() => { this.isLoggedIn(false); });
  
  }

  componentWillUnmount() {
    Hub.remove('auth');
  }

  isLoggedIn(desired) {
    // console.log('isLoggedIn.state', desired)
    this.setState({ loggedInStatus: desired})
  }

  handleClick() {
    this.setState({ prevMenuOpen: !this.state.prevMenuOpen });
  };

  toggleMenu(event) {
    this.setState({ anchorEl: event.currentTarget, open: !this.state.open });
  };

  toggleDrawer() {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  render() {
    const { loggedInStatus } = this.state;
    // console.log('render.loggedInStatus', loggedInStatus)
    return (
      <>
        <AppBar
          position="sticky"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              // style={{ marginRight: '2rem' }}
              onClick={this.toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            {/* <div style={{ flexGrow: 1 }} /> */}
            <Typography variant="h6" 
            // style={{ flexGrow: 1 }}
            >
              
              <Button 
                component={GLink}
                to='/'>
                  {/* <div className='iconheader' /> */}
                  {this.props.siteTitle}</Button>
            </Typography>
            {loggedInStatus &&
              <>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.toggleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.open}
                  onClose={this.toggleMenu}
                >
                  <MenuItem 
                    component={GLink}
                    to='/admin/'>Admin</MenuItem>
                  <SignOut />
                </Menu>
              </>
            }
            {!loggedInStatus &&
              <Button
                variant="outlined"
                component={GLink}
                to='/admin/'
                // href="/app/progress"
             >Login</Button>
            }
          </Toolbar>
        </AppBar>
        <Drawer anchor='left' open={this.state.drawerOpen} onClose={this.toggleDrawer}>
          <nav aria-label="main drawer menu">
            <List>
              <MenuItem
                component={GLink}
                href="https://www.excitingtheory.com"
              >Home</MenuItem>
              {/* <MenuItem
                component={GLink}
                href="https://live.excitingtheory.com"
              >Live</MenuItem> */}
              <MenuItem
                component={GLink}
                href="https://japanese.excitingtheory.com"
              >Japanese</MenuItem>
              {/* <MenuItem
                component={GLink}
                to="/katakana-letter-practice"
              >Katakana Letter Practice</MenuItem>
              <MenuItem
                component={GLink}
                to="/sound-recognition"
              >Sound Recognition</MenuItem>
              <MenuItem
                component={GLink}
                to="/meaning-association"
              >Meaning Association</MenuItem> */}
            </List>
          </nav>
        </Drawer>
      </>
    )
  }
}

export default Header
